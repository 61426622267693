// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("chartkick")
require("chart.js")

import "controllers";
import "bootstrap";
import "@fortawesome/fontawesome-free/js/all";
import "datatables.net-bs4";
import Tagify from "@yaireo/tagify";
import "chartkick/chart.js"
import "./custom";

import "../stylesheets/application.scss";
import "../stylesheets/admin.scss";
import "../stylesheets/sales.scss";
import "../stylesheets/shipping.scss";
import "../stylesheets/warehouse.scss";
import "../stylesheets/media.scss";
import "@yaireo/tagify/dist/tagify.css";

window.Tagify = Tagify;
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import $ from 'jquery';
global.$ = jQuery;
